import React                 from 'react';
import { Flex, Box, Center } from '@chakra-ui/react';
import Img                   from 'gatsby-image';
import { graphql }           from 'gatsby';

import Heading       from '@interness/web-core/src/components/text/Heading/Heading';
import HeaderMedia   from '@interness/theme-default/src/components/HeaderMedia';
import Wrapper       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import { findMedia } from '@interness/web-core/src/components/_helpers';
import Spacer        from '@interness/web-core/src/components/structure/Spacer/Spacer';

import Subnavigation from '../components/Subnavigation';
import Separator     from '@interness/web-core/src/components/structure/Separator/Separator';
import Link          from '@interness/web-core/src/components/elements/Link/Link';

const VermietungPage = (props) => {
  return (
    <>
      <HeaderMedia id="produkte"/>
      <Subnavigation title="Produkte" menu={[
        {
          'displayName': 'Fertigung',
          'path': '/fertigung'
        },
        {
          'displayName': 'Europaletten',
          'path': '/europaletten'
        },
        {
          'displayName': 'Paletten 1/2/3 weg',
          'path': '/paletten-123-weg'
        },
        {
          'displayName': 'Sonderpaletten',
          'path': '/sonderpaletten'
        },
        {
          'displayName': 'Chemiepaletten',
          'path': '/chemiepaletten'
        },
        {
          'displayName': 'Euro-Gitterboxen',
          'path': '/euro-gitterboxen'
        },
        {
          'displayName': 'Vermietung / Reparaturen',
          'path': '/vermietung-reparaturen'
        }
      ]}/>
      <Spacer/>
      <Wrapper>
        <Heading>EUROPALETTEN UND GITTERBOXPALETTEN</Heading>
        <Spacer/>
        <div>
          <h3>Vermietung von Gitterboxen und Europaletten</h3>
          <p>Die mittel- und langfristige Disposition der zu transportierenden Güter ist oft nicht möglich. Hier bietet
            unser Vermietungsservice eine sinnvolle, vor allem wirtschaftlich interessante Alternative.</p>
          <p>Durch die Anmietung von Paletten lassen sich Kosten- und Liquidität optimieren.</p>
          <p>Rufen Sie uns an, gerne beraten wir Sie über Vorteile und Durchführung einer zeitlich flexiblen Anmietung
            von Europaletten und Epal-Gitterboxen.</p>
          <p>Ihre Vorteile unseres Vermiet-Services</p>
          <ul>
            <li>keine Kapitalbindung</li>
            <li>flexible Disposition</li>
            <li>schneller Service</li>
          </ul>
        </div>
        <Separator/>
        <div>
          <h3>Reparatur von Europaletten und Gitterboxpaletten</h3>
          <p>Paletten, die Beschädigungen aufweisen verfügen oft nicht mehr über die geforderte Tragfähigkeit oder sind
            aufgrund ihres schlechten Zustandes für einen Austausch ungeeignet.</p>
          <p>Sie entsprechen somit nicht mehr den Qualitätsrichtlinien und werden von uns fachkundig nach den Kriterien
            der EPAL (European Pallet Association) repariert.</p>
          <p>Reparaturprüfnagel mit EPAL-Qualitätszeichen für qualitätsgeprüfte Flachpaletten nach der Reparatur.</p>
          <p>Bei der Reparatur nach EPAL werden</p>
          <ul>
            <li>fehlende Klötze eingebaut, beschädigte Klötze ausgetauscht</li>
            <li>schadhafte Bretter ersetzt</li>
            <li>Nagel- oder Schraubenschäfte so bearbeitet, das diese nicht mehr sichtbar sind</li>
          </ul>
        </div>
        <div>
          <h3>Reparaturfirmen</h3>
          <p>Nur von der EPAL zugelassene Reparaturfirmen dürfen Gitterboxpaletten reparieren. Unsere Mitarbeiter
            verfügen über die vorgeschriebene Qualifikation : Schweißereignungsnachweis nach DIN 18800 Blatt 7.</p>
          <p>Bei der Reparatur von Gitterboxpaletten werden</p>
          <ul>
            <li>defekte Rundstahlgitter und Bodenbretter ausgetauscht</li>
            <li>Verformungen gerichtet und begradigt u.a. Steilwinkelaufsatz, Ecksäulen, Bodenrahmen</li>
            <li>der Allgemeinzustand geprüft; z.B. Rost, Verschmutzung und Funktionsfähigkeit der Vorderwandklappe</li>
          </ul>
        </div>
        <Spacer/>
        <Flex flexWrap="wrap" justifyContent="center">
          <Box width={['100%', '100%', '33%', '33%']}>
            <Center>
              <Img fixed={findMedia(props.data.collection.images, 'pg-rep-1').childImageSharp.fixed}/>
            </Center>
          </Box>
          <Box width={['100%', '100%', '33%', '33%']}>
            <Center>
              <Img fixed={findMedia(props.data.collection.images, 'pg-rep-2').childImageSharp.fixed}/>
            </Center>
          </Box>
          <Box width={['100%', '100%', '33%', '33%']}>
            <Center>
              <Img fixed={findMedia(props.data.collection.images, 'pg-rep-3').childImageSharp.fixed}/>
            </Center>
          </Box>
          <Box width={['100%', '100%', '33%', '33%']}>
            <Center>
              <Img fixed={findMedia(props.data.collection.images, 'pg-rep-4').childImageSharp.fixed}/>
            </Center>
          </Box>
          <Box width={['100%', '100%', '33%', '33%']}>
            <Center>
              <Img fixed={findMedia(props.data.collection.images, 'pg-rep-5').childImageSharp.fixed}/>
            </Center>
          </Box>
          <Box width={['100%', '100%', '33%', '33%']}>
            <Center>
              <Img fixed={findMedia(props.data.collection.images, 'pg-rep-6').childImageSharp.fixed}/>
            </Center>
          </Box>
        </Flex>
        <Separator/>
        <div>
          <h3>Reparatur-Plakette</h3>
          <p>Nur qualitätsgeprüfte Gitterboxpaletten erhalten nach der Reparatur eine grüne (Reparatur) Plakette mit dem
            EPAL Qualitätszeichen.</p>
          <Center>
            <Img style={{ margin: '0 10px' }}
                 fixed={findMedia(props.data.collection.images, 'reparaturplakette').childImageSharp.fixed}/>
            <Link external to={findMedia(props.data.pdf.media, 'lizenz_2015_reparatur_gibo').publicURL}>Download PEFC
              Zertifikat
              (PDF)</Link>
          </Center>
        </div>
      </Wrapper>
      <Spacer/>
    </>
  )
};

export default VermietungPage;


export const query = graphql`
    query {
        collection: directusMediaCollection(name: {eq: "vermietung"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fixed {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
            }
        }
        pdf: directusMediaCollection(name: {eq: "pdf"}) {
            media {
                file {
                    localFile {
                        name
                        publicURL
                    }
                }
            }
        }
    }
`;